/*globals context */
/*globals __webpack_public_path__ */
/*eslint no-undef: "error"*/

import React from "react";
import ReactDOM from "react-dom";

if (context?.dsId !== undefined) {
  __webpack_public_path__ = context.basePath;

  const TransferApp = React.lazy(() => import("./components/MasterDV/GroupTicketAssignment"));
  
  ReactDOM.render(
    <React.Suspense fallback={ <></> }>
      <TransferApp { ...context } isMyHL />
    </React.Suspense>
    ,
    document.getElementById("root")
  );
} else {
  const CeaApp = React.lazy(() => import("./AppWrapper"));
  
  ReactDOM.render(
    <React.Suspense fallback={ <></> }>
      <CeaApp />
    </React.Suspense>,
    document.getElementById("root")
  );
}
